/**
 * @author Manifold Finance, Inc.
 * @date July, 12, 2023
 * @license GPL-3.0
 */
import { SessionProvider } from 'next-auth/react';
import '@/styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import { NEXT_PUBLIC_GOOGLE_ANALYTICS_ID } from '@/constants/addresses';
import { Database } from '@/lib/database';
import { supabase } from '@/lib/supabaseClient';
import { GoogleAnalytics, GoogleTagManager } from '@next/third-parties/google';
import { connectorsForWallets, darkTheme, RainbowKitProvider, Theme } from '@rainbow-me/rainbowkit';
import {
  argentWallet,
  coinbaseWallet,
  frameWallet,
  injectedWallet,
  metaMaskWallet,
  phantomWallet,
  rabbyWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Analytics } from '@vercel/analytics/react';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { atom, Provider } from 'jotai';
import { AxiomWebVitals } from 'next-axiom';
import PlausibleProvider from 'next-plausible';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
//import { useRouter } from 'next/router';
import React, { use, useEffect } from 'react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { goerli, hardhat, mainnet } from 'wagmi/chains';
//? SEE FIXME BELOW
// import { goerli, mainnet } from 'wagmi/chains';
//import 'dotenv/config';
//import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

export { useReportWebVitals } from 'next-axiom';

// FIXME: remove const infura_rpc_url
//const INFURA_API_KEY = 'fe24c1ff7cfb4ddabec77f512e60c5b6';
const dev = {
  id: 1337,
  name: 'Hardhat',
  network: 'hardhat',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['http://127.0.0.1:8545'],
    },
    public: {
      http: ['http://127.0.0.1:8545'],
    },
  },
};
const { chains, publicClient } = configureChains(
  [mainnet, goerli],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://lb.drpc.org/ogrpc?network=ethereum&dkey=AqNJlAXGv0pQueXw5XHt4ievvkLFm5QR7rAb0oup5x2S`,
      }),
    }),
    infuraProvider({ apiKey: 'fe24c1ff7cfb4ddabec77f512e60c5b6' }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://api.securerpc.com/v1`,
      }),
    }),
    publicProvider(),
  ],
);
export const stakedAmount = atom('0');
export const stakedTxHash = atom('');
export const stakedTxSuccessMessage = atom('');
export const writeTxErrorMessage = atom('');
export const stakedTxStatus = atom<'None' | 'Loading' | 'Success' | 'Error' | 'Idle'>('None');

export const stakedAmountClaim = atom('');
export const stakedTxHashClaim = atom('');
export const stakedTxSuccessMessageClaim = atom('');
export const writeTxErrorMessageClaim = atom('');
export const stakedTxStatusClaim = atom<'None' | 'Claiming' | 'Success' | 'Error' | 'Idle'>('None');

export const stakedAmountSwapExactTokensForETH = atom('');
export const stakedTxHashSwapExactTokensForETH = atom('');
export const stakedTxSuccessMessageSwapExactTokensForETH = atom('');
export const writeTxErrorMessageSwapExactTokensForETH = atom('');
export const stakedTxStatusSwapExactTokensForETH = atom<
  'None' | 'Swapping' | 'Success' | 'Error' | 'Idle'
>('None');

// TODO: add chains and correct project id
const PROJECT_ID = 'ac47eb032c85961fad7d3ea789e99ed7';

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      metaMaskWallet({
        projectId: PROJECT_ID,
        chains,
      }),
      walletConnectWallet({
        projectId: PROJECT_ID,
        chains,
      }),
      coinbaseWallet({
        appName: 'meveth',
        chains,
      }),
      trustWallet({
        projectId: PROJECT_ID,
        chains,
      }),
      phantomWallet({
        chains,
      }),
      rainbowWallet({
        projectId: PROJECT_ID,
        chains,
      }),
      rabbyWallet({
        chains,
      }),
      frameWallet({
        chains,
      }),
      injectedWallet({
        chains,
      }),
      argentWallet({
        projectId: PROJECT_ID,
        chains,
      }),
    ],
  },
]);

const wagmiConfig = createConfig({
  publicClient,
  connectors,
});
/**
 * @config next-plausible
 * @const NEXT_PUBLIC_DOMAIN
 * @returns 'manifoldfinance.com'
 */

// export const NEXT_PUBLIC_DOMAIN = 'testing-meveth.vercel.app';
// export const NEXT_PUBLIC_DOMAIN = 'mev.io';
export const NEXT_PUBLIC_DOMAIN = 'mev.io';

export function reportVercelMetrics(metric: NextWebVitalsMetric) {
  console.log(metric);
}
export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const router = useRouter();

  // style={{ scrollBehavior: "smooth" }}
  // add this to the body tag after the page loads
  // to make the page scroll smoothly
  // https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

  useEffect(() => {
    document.body.style.scrollBehavior = 'smooth';
    const html = document.querySelector('html');
    if (html) {
      html.style.scrollBehavior = 'smooth';
    }
  }, []);
  useEffect(() => {
    const fetcher = async () => {
      const res = await fetch('/api/geo');
      const status = await res.status;
      console.log('statusText', status);
      if (status === 403) {
        console.log('403', 'verbotten');
        router.push('/403');
      } else {
        console.log('200', 'ok');
      }
    };
    fetcher();
  }, []);
  return (
    // @ts-ignore
    <PlausibleProvider domain={process.env.NEXT_PUBLIC_DOMAIN}>
      <GoogleAnalytics gaId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
      <SessionProvider session={session}>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            modalSize="compact"
            theme={darkTheme({
              accentColor: '#00bfff',
              borderRadius: 'medium',
              overlayBlur: 'small',
            })}
            chains={chains}
          >
            <Provider>
              <AxiomWebVitals />
              <Component {...pageProps} />
              <Analytics />
            </Provider>
          </RainbowKitProvider>
        </WagmiConfig>
      </SessionProvider>
    </PlausibleProvider>
  );
}
