console.log('processenvts', process.env.NEXT_PUBLIC_MEVETH_CONTRACT);

export const MEVETH_CONTRACT =
  (process.env.NEXT_PUBLIC_MEVETH_CONTRACT as unknown as `0x${string}` | undefined) ||
  '0x24ae2da0f361aa4be46b48eb19c91e02c5e4f27e';
export const SPLIT_SWAP_ROUTER_CONTRACT =
  (process.env.NEXT_PUBLIC_SPLIT_SWAP_ROUTER_CONTRACT as unknown as `0x${string}` | undefined) ||
  '0x77337dEEA78720542f0A1325394Def165918D562';
export const MEVETH_ROUTER_CONTRACT =
  (process.env.NEXT_PUBLIC_MEVETH_ROUTER_CONTRACT as unknown as `0x${string}` | undefined) ||
  '0xb60598cd27fb881b92a34aFcE93aA1ee647B4A93';

export const WETH_CONTRACT =
  (process.env.NEXT_WETH_CONTRACT as unknown as `0x${string}` | undefined) ||
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
export const MULTISIG_CONTRACT =
  (process.env.NEXT_MULTISIG_CONTRACT as unknown as `0x${string}` | undefined) ||
  '0x617c8dE5BdE54ffbb8d92716CC947858cA38f582';
export const NEXT_PUBLIC_SUPABASE_URL =
  (process.env.NEXT_PUBLIC_SUPABASE_URL as string) || 'https://ihaetizatgziqsfrnuqd.supabase.co';
export const NEXT_PUBLIC_SUPABASE_ANON_KEY =
  (process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY as string) ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImloYWV0aXphdGd6aXFzZnJudXFkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY2NzIwMDMsImV4cCI6MjAyMjI0ODAwM30.gJJAYBOZdsTxGptRl_FXJQ9sHR4pc3t8ehVRnzgdyIs';
export const SUPABASE_SECRET = (process.env.SUPABASE_SECRET as string) || '';

export const NEXT_PUBLIC_URL = (process.env.NEXT_PUBLIC_URL as string) || 'https://www.mev.io';
//openssl rand -base64 32
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET as string;

export const NEXT_PUBLIC_TWITTER_CLIENT_ID = process.env.NEXT_PUBLIC_TWITTER_CLIENT_ID as string;
export const TWITTER_SECRET = process.env.TWITTER_SECRET as string;

export const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID =
  (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string) || 'G-HP2WSKDTD2';
